import * as React from 'react';
import { useState, useEffect, Fragment } from "react";
import { useLocation } from 'react-router-dom';

import { Box, Grid, Typography, Dialog, Alert, Button, Container, DialogContent, DialogTitle, TextField } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { ClearAll, CookieOutlined, LinkOutlined, Person2Outlined } from '@mui/icons-material';

import Footer from '../shared/footer';
import Volume from './volume';

export default function Dashboard(props) {

    const location = useLocation();

    const [isLogginLoaded, setIsLogginLoaded] = useState(false)
    const [volumeRichiesto, setVolumeRichiesto] = useState(undefined)

    const [utente, setUtente] = useState({});

    const [showVolumeRichiesto, setShowVolumeRichiesto] = useState(false)

    const [listVolumi, setListVolumi] = useState([]);
    const [listVolumiPlaceholder, setListVolumiPlaceholder] = useState([]);
    const [volumiLoaded, setVolumiLoaded] = useState(false)
    const [errorDialogOpen, setErrorDialogOpen] = useState(false)
    const [errorMsgDialog, setErrorMsgDialog] = useState("")

    const [dialogTestQuestionarioGratuitoOpen, setDialogTestQuestionarioGratuitoOpen] = useState(false);
    const [linkQuestionarioGratuito, setLinkQuestionarioGratuito] = useState("");
    const [hasLinkQuestionarioGratuitoError, setHasLinkQuestionarioGratuitoError] = useState(false);
    const [isCheckingPermalink, setIsCheckingPermalink] = useState(false);

    useEffect(() => {

        let url = window.location.href;

        if (process.env.REACT_APP_IS_DEBUG == "false") {
            const params = new URLSearchParams(window.location.search)
            if (params.has("token")) {
                localStorage.setItem('token', params.get("token"));
                login(params.get("token"))
                let newUrl = url.split('?')[0];
                window.history.pushState("", "", newUrl);
            } else {
                if (localStorage.getItem("isLoggedFO") != null) {
                    if (localStorage.getItem("isLoggedFO") == "true") {
                        if (localStorage.getItem("volumeRichiesto") && location.state?.volumeSelezionato != undefined && location.state?.volumeSelezionato == true) {
                            let isbnRichiesto = localStorage.getItem("volumeRichiesto");
                            setVolumeRichiesto(isbnRichiesto);
                            getQuestionariVolumeRichiesto(isbnRichiesto);
                            setShowVolumeRichiesto(true)
                            setVolumiLoaded(true)
                            setIsLogginLoaded(true);
                        } else {
                            getPurchasedList();
                        }
                    } else {
                        window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                    }
                } else {
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
                    setErrorMsgDialog("Abbiamo riscontrato dei problemi. Effettua nuovamente il login.")
                    setErrorDialogOpen(true);
                }
            }
        } else {
            const params = new URLSearchParams(window.location.search)
            if (params.has("token")) {
                localStorage.setItem('token', params.get("token"));
                login(params.get("token"))
                let newUrl = url.split('?')[0];
                window.history.pushState("", "", newUrl);
            } else if (localStorage.getItem("isLoggedFO") != null) {
                if (localStorage.getItem("isLoggedFO") == "true") {
                    if (localStorage.getItem("volumeRichiesto") && location.state?.volumeSelezionato != undefined && location.state?.volumeSelezionato == true) {
                        let isbnRichiesto = localStorage.getItem("volumeRichiesto");
                        setVolumeRichiesto(isbnRichiesto);
                        getQuestionariVolumeRichiesto(isbnRichiesto);
                        setShowVolumeRichiesto(true)
                        setVolumiLoaded(true)
                        setIsLogginLoaded(true);
                    } else {
                        getPurchasedList();
                    }
                } else {
                    login("A7x702zwnOQtBkiDrWkACP3lcWstRrlp3S5KK7sul9wx89Lk1X2tyg9VEnh9ScRNHelrgPcrBnbMOZpca5GDIKk=")
                }
            } else {
                login("A7x702zwnOQtBkiDrWkACP3lcWstRrlp3S5KK7sul9wx89Lk1X2tyg9VEnh9ScRNHelrgPcrBnbMOZpca5GDIKk=")
            }
        }
    }, [])


    //Modalità debug
    function abilitaAlertCookie() {
        if (props.abilitaAlertCookie)
            props.abilitaAlertCookie();
    }
    function openDialogQuestionarioGratuito() {
        setLinkQuestionarioGratuito("");
        setDialogTestQuestionarioGratuitoOpen(prev => !prev)
    }
    function changeLinkQuestionarioGratuito(e) {
        setLinkQuestionarioGratuito(e.target.value);
    }
    function cleanLocalStorageAndReloadDebug(showCookies) {
        localStorage.clear();
        if (!showCookies)
            localStorage.setItem('cookieAccepted', "true"); //Li setto a true senno ogni volta ri-appare l'alert dei cookies
        window.location.reload();
    }

    async function permalinkQuestionarioGratuitoCheck() {
        let permalink = linkQuestionarioGratuito;
        if (permalink != "" && permalink != undefined && permalink != null) {
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    'permalink': permalink,
                })
            };
            setIsCheckingPermalink(true)

            let url = process.env.REACT_APP_FETCH_URL + 'questionario/permalink/check';
            try {
                let res = await fetch(url, requestOptions);
                if (res.status === 200) {
                    res.json().then(
                        result => {
                            permalinkResolver(permalink)
                        }
                    )
                }
                else {
                    setHasLinkQuestionarioGratuitoError(true);
                    setIsCheckingPermalink(false)

                }
            } catch (error) {
                setIsCheckingPermalink(false)
            }
        }
    }
    async function permalinkResolver(permalink) {

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                'permalink': permalink,
            })
        };
        let url = process.env.REACT_APP_FETCH_URL + 'questionario/permalink/resolve';
        try {
            let res = await fetch(url, requestOptions);
            if (res.status === 200) {
                res.json().then(
                    result => {
                        setIsCheckingPermalink(false)
                        setHasLinkQuestionarioGratuitoError(false);
                        window.open(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sim/" + permalink, "_blank");
                    }
                )
            }
            else {
                setIsCheckingPermalink(false)
                setHasLinkQuestionarioGratuitoError(true);
            }


        } catch (error) {
        }

    }
    async function login(token) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        };

        const url = `${process.env.REACT_APP_FETCH_URL}auth/loginWP`;

        try {
            const res = await fetch(url, requestOptions);

            if (res.status === 200) {
                const result = await res.json();
                const utenteTmp = {
                    id: result.id,
                    ruolo: result.ruolo,
                    email: result.email,
                    nome: result.nome
                };

                setUtente(utenteTmp);

                const utenteTmpJson = JSON.stringify(utenteTmp);

                localStorage.setItem('id', result.id);
                localStorage.setItem('email', result.email);
                localStorage.setItem('isLoggedFO', true);
                localStorage.setItem('utente', utenteTmpJson);
                localStorage.setItem('volumeRichiesto', result.volumeRichiesto);

                setIsLogginLoaded(true);

                if (!location.state) {
                    setVolumeRichiesto(result.volumeRichiesto);

                    if (result.volumeRichiesto) {
                        getQuestionariVolumeRichiesto(result.volumeRichiesto);
                        setShowVolumeRichiesto(true);
                    } else {
                        getPurchasedList();
                        setListVolumiPlaceholder(result.volumi);
                        setShowVolumeRichiesto(false);
                    }
                } else {
                    if (location.state.isbn) {
                        setVolumeRichiesto(location.state.isbn);
                    }

                    setShowVolumeRichiesto(false);
                    getPurchasedList();
                    setListVolumiPlaceholder(result.volumi);
                }
            } else {
                localStorage.setItem('isLoggedFO', false);
                localStorage.removeItem('token');
                localStorage.removeItem('utente');
                window.location.href = `${process.env.REACT_APP_PUBLIC_URL}${process.env.REACT_APP_PREFIX}/sessione-scaduta`;
            }
        } catch (error) {
            console.error('Errore durante il login: ', error);
        }
    }
    async function getQuestionariVolumeRichiesto(isbn) {
        let url = process.env.REACT_APP_FETCH_URL + "questionario/list/byISBN/" + isbn;
        try {
            let res = await fetch(url);
            if (res.status === 200) {
                res.json().then(
                    result => {
                        let listVolumiTmp = [{
                            isbn: isbn,
                            codice: result.data.volume.titolo,
                            questionari: result.data.questionari,
                            copertina: result.data.volume.copertina,
                            titolo: result.data.volume.titolo,
                        }]
                        setListVolumi(listVolumiTmp)
                        setVolumiLoaded(true)
                    }
                )
            } else if (res.status === 401) {
                res.json().then(
                    result => {
                        if (process.env.REACT_APP_IS_DEBUG == "false")
                            window.location.href = process.env.REACT_APP_PUBLIC_URL + "/" + process.env.REACT_APP_PREFIX + "/sessione-scaduta";

                        setErrorMsgDialog(result.data[0].msg)
                        setErrorDialogOpen(true)
                    }
                )
            } else if (res.status === 403) {
                if (process.env.REACT_APP_IS_DEBUG == "false")
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
            } else if (res.status === 404) {
                getPurchasedList();
            }
        } catch (error) { }
    }
    async function getPurchasedList() {
        let url = process.env.REACT_APP_FETCH_URL + "questionario/list/purchased";
        try {
            let res = await fetch(url);
            if (res.status === 200) {
                res.json().then(
                    result => {
                        if (result.success) {

                            setListVolumi(result.data)
                            setVolumiLoaded(true)
                            setIsLogginLoaded(true);

                        } else {
                            setErrorMsgDialog(result.data[0].msg)
                            setErrorDialogOpen(true)
                        }
                    }
                )
            }
            else if (res.status === 401) {
                res.json().then(
                    result => {
                        if (process.env.REACT_APP_IS_DEBUG == "false")
                            window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";

                        setErrorMsgDialog(result.data[0].msg)
                        setErrorDialogOpen(true)
                    }
                )

            } else if (res.status === 403) {
                if (process.env.REACT_APP_IS_DEBUG == "false")
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
            } else if (res.status === 500) {
                res.json().then(
                    result => {
                        setErrorMsgDialog(result.data[0].msg)
                        setErrorDialogOpen(true)
                    })
            }
        } catch (error) { }
    }
    return (
        <Fragment>
            {!isLogginLoaded || (showVolumeRichiesto && !volumiLoaded) ? (
                <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            animationDuration: '550ms',
                            mt: 2,
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={50}
                        thickness={4} />
                </Box>
            ) : (
                <Container maxWidth="xl" sx={{ minHeight: '90vh', display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>

                    <Box sx={{ mt: 2, mb: 5 }}>
                        {process.env.REACT_APP_IS_DEBUG == "true" && (
                            <Alert sx={{ mb: 2 }} severity="warning">Attualmente sei in modalità <b>debug</b></Alert>
                        )}
                        <Typography sx={{ mb: 2 }} variant="h2" >I Tuoi simulatori</Typography>
                        <Grid container direction="row" justifyContent="start" alignItems="stretch" spacing={3}>
                            {volumiLoaded ? (
                                listVolumi.map((volume, index) => (
                                    <Fragment key={index}>
                                        <Volume volume={volume} volumiLoaded={volumiLoaded} key={index} utente={utente}
                                            isbnVolumeRichiesto={volumeRichiesto} showVolumeRichiesto={showVolumeRichiesto} />
                                    </Fragment>

                                ))) :
                                (
                                    !showVolumeRichiesto && (
                                        listVolumiPlaceholder.map((volume, index) => (
                                            <Grid item xs={12} md={4} lg={3} xl={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <Box sx={{
                                                    height: { xs: '450px', md: '400px', xl: '500px' }, border: '1px solid #003370',
                                                    borderRadius: '5px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <CircularProgress
                                                            variant="indeterminate"
                                                            disableShrink
                                                            sx={{
                                                                animationDuration: '550ms',
                                                                mt: 2,
                                                                left: 0,
                                                                [`& .${circularProgressClasses.circle}`]: {
                                                                    strokeLinecap: 'round',
                                                                },
                                                            }}
                                                            size={50}
                                                            thickness={4} />
                                                        <Typography sx={{ mt: 3 }}>Caricamento volume...</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )))
                                )}
                        </Grid>

                        {process.env.REACT_APP_IS_DEBUG == "true" && (
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ my: 2 }} variant="h4" >Opzioni sviluppatore</Typography>
                                <Button disabled variant="outlined" fullWidth sx={{ my: 1 }} endIcon={<Person2Outlined />}> Cambia token utente</Button>
                                <Button onClick={openDialogQuestionarioGratuito} variant="outlined" fullWidth endIcon={<LinkOutlined />}>Prova questionario gratuito</Button>
                                <Button onClick={abilitaAlertCookie} variant="outlined" fullWidth sx={{ my: 1 }} endIcon={<CookieOutlined />}> Abilita alert cookie</Button>
                            </Box>
                        )}
                    </Box>
                    <Footer />
                </Container>
            )
            }
            <Dialog open={errorDialogOpen}>
                <DialogContent>
                    <Typography>{errorMsgDialog}</Typography>
                    {process.env.REACT_APP_IS_DEBUG && (
                        <>
                            <Button sx={{ mt: 2 }} color='error' variant='outlined' onClick={() => cleanLocalStorageAndReloadDebug(false)} fullWidth >Pulisci localstorage e ricarica pagina</Button>
                            <Button sx={{ mt: 2 }} color='error' variant='text' onClick={() => cleanLocalStorageAndReloadDebug(true)} fullWidth >Pulisci localstorage (ma mostra alert cookie) e ricarica pagina</Button>
                        </>
                    )}
                </DialogContent>

            </Dialog>

            <Dialog fullWidth maxWidth="lg" open={dialogTestQuestionarioGratuitoOpen} onClose={openDialogQuestionarioGratuito}>
                <DialogTitle>
                    Prova un questionario gratuito
                </DialogTitle>
                <DialogContent>
                    <Typography >
                        Cliccando sul pulsante di conferma si aprirà una nuova scheda nel browser e verrai rimandato al seguente link: <br></br>
                    </Typography>
                    <Typography sx={{ mb: 2, textDecoration: 'underline' }}>
                        {process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sim/"}<b>{linkQuestionarioGratuito}</b>
                    </Typography>
                    {hasLinkQuestionarioGratuitoError && (
                        <Alert severity="error" sx={{ mb: 3 }}>Non esiste nessun questionario gratuito con questo link. Controlla che lo stato del questionario sia "<b>pubblicato</b>"</Alert>
                    )}

                    <TextField fullWidth label="Link questionario gratuito" variant="outlined" onChange={changeLinkQuestionarioGratuito} />

                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'end', mt: 2 }}>
                        <Button disabled={isCheckingPermalink} variant="outlined" onClick={openDialogQuestionarioGratuito}>Chiudi finestra</Button>
                        <Button disabled={isCheckingPermalink} variant="contained" onClick={permalinkQuestionarioGratuitoCheck} sx={{ ml: 1 }}>Conferma e prosegui</Button>
                    </Box>
                </DialogContent>


            </Dialog>
        </Fragment>

    )
}


