import * as React from 'react';
import { Component } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';


export class RowDomanda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index,
            listQuiz: this.props.listQuiz,
        }

    }

    changeColorAnswerText = (domanda, choice) => {
        //this.props.changeColorAnswerText(domanda, choice)
        if (choice.value === domanda.givenAnswer) { //Se è la domanda selezionata
            if (!domanda.correctAnswer.includes(choice.value)) {
                return "red"
            }
        }

        if (domanda.correctAnswer.includes(choice.value))
            return "green"
    }

    render() {
        return (
            <Box px={{ sx: 0, md: 3 }} sx={{ my: 2 }}>

                <Paper elevation={5} sx={{ p: { xs: 1, md: 5 }, mx: { xs: 1, md: 2 } }}>
                    <Typography variant="subtitle2" >
                        Risposta n. {this.state.index + 1}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Typography display={{ xs: 'none', md: 'flex' }} variant="h5">
                            <div style={{ overflowY: 'auto', maxHeight: '20vh' }} dangerouslySetInnerHTML={{ __html: this.state.listQuiz[this.state.index].title }}></div>
                        </Typography>
                        <Typography display={{ xs: 'flex', md: 'none' }} sx={{ mb: 2, mt: 1 }} variant="h6">
                            <div  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.listQuiz[this.state.index].title) }}></div>
                        </Typography>
                    </Box>
                    {this.state.listQuiz[this.state.index].choices.map((choice, i) => (
                        <Box key={i} sx={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'start', mt: { xs: 2, md: 2 } }} >
                            <Box sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                            <Typography variant="body1" sx={{ mr: 1 }}>
                                {(i + 10).toString(36).toUpperCase()})
                            </Typography>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choice.text) }} />

                            </Box>
                            {Array.isArray(this.state.listQuiz[this.state.index].givenAnswer) ? (
                                <Typography variant="subtitle2" sx={{ color: this.changeColorAnswerText(this.state.listQuiz[this.state.index], choice) }}>
                                    {(this.state.listQuiz[this.state.index].givenAnswer.includes(choice.value) &&
                                        !this.state.listQuiz[this.state.index].correctAnswer.includes(choice.value)) && (
                                            <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Errata) <b>({this.state.listQuiz[this.state.index]?.puntiErrata} punti)</b></span>
                                        )}
                                    {(!this.state.listQuiz[this.state.index].givenAnswer.includes(choice.value) &&
                                        this.state.listQuiz[this.state.index].correctAnswer.includes(choice.value)) && (
                                            <span style={{ marginLeft: '10px' }}>(Corretta)</span>
                                        )}
                                    {/* this.state.questionarioCompletato[index].correctAnswer.includes(this.state.questionarioCompletato[index].givenAnswer) && */}
                                    {(this.state.listQuiz[this.state.index].givenAnswer.includes(choice.value) && this.state.listQuiz[this.state.index].correctAnswer.includes(choice.value)) &&
                                        (
                                            <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Corretta) <b>({choice?.puntiCorretta !== undefined ? choice?.puntiCorretta : this.state.listQuiz[this.state.index]?.puntiCorretta} punti)</b></span>
                                        )}
                                </Typography>
                            ) :
                                (
                                    <Typography variant="subtitle2" sx={{ color: this.changeColorAnswerText(this.state.listQuiz[this.state.index], choice) }}>
                                        {(choice.value === this.state.listQuiz[this.state.index].givenAnswer && !this.state.listQuiz[this.state.index].correctAnswer.includes(this.state.listQuiz[this.state.index].givenAnswer)) && (
                                            <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Errata) <b>({this.state.listQuiz[this.state.index]?.puntiErrata} punti)</b></span>
                                        )}
                                        {(choice.value !== this.state.listQuiz[this.state.index].givenAnswer &&
                                            this.state.listQuiz[this.state.index].correctAnswer.includes(choice.value)) && (
                                                <span style={{ marginLeft: '10px' }}>(Corretta)</span>
                                            )}
                                        {/* this.state.questionarioCompletato[index].correctAnswer.includes(this.state.questionarioCompletato[index].givenAnswer) && */}
                                        {(choice.value === this.state.listQuiz[this.state.index].givenAnswer &&
                                            this.state.listQuiz[this.state.index].correctAnswer.includes(this.state.listQuiz[this.state.index].givenAnswer)) &&
                                            (
                                                <span style={{ marginLeft: '10px' }}>(Risposta Fornita - Corretta) <b>({choice?.puntiCorretta !== undefined ? choice?.puntiCorretta : this.state.listQuiz[this.state.index]?.puntiCorretta} punti)</b></span>
                                            )}
                                    </Typography>
                                )}

                        </Box>
                    ))}

                    {Array.isArray(this.state.listQuiz[this.state.index].givenAnswer) ? (
                        this.state.listQuiz[this.state.index].givenAnswer.length === 0 && (
                            <Typography variant="subtitle1" sx={{ mt: 1, color: 'red' }}>Risposta Omessa <b>({this.state.listQuiz[this.state.index]?.puntiOmessa} punti)</b></Typography>
                        )
                    ) : (
                        this.state.listQuiz[this.state.index].givenAnswer == "" && (
                            <Typography variant="subtitle1" sx={{ mt: 1, color: 'red' }}>Risposta Omessa <b>({this.state.listQuiz[this.state.index]?.puntiOmessa} punti)</b></Typography>
                        )
                    )}
                </Paper>
            </Box>
        )
    }
}
