import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Box, CardHeader } from '@mui/material';
import { red, green, blue } from '@mui/material/colors';
import { BaseOptionChart } from './charts';


// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------


export default function PieGraph(props) {
  const theme = useTheme();
  let datiDomande = [props.corrette, props.errate, props.omesse];

  const chartOptions = merge(BaseOptionChart(), {

    colors: [
      green[700],
      red[700],
      blue[700]
    ],
    labels: ['Risposte Corrette', 'Risposte Errate', 'Risposte Omesse'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    type: 'donut',
    plotOptions: {
      pie:
      {
        donut:
        {
          size: '65%',
          background: 'transparent',
          labels:
            { show: false }
        }
      }

    }
  });

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <CardHeader />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="donut" series={datiDomande} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Box>
  );
}
