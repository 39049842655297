import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

export default function DebugToken(props) {

    const [tokenAssente, setTokenAssente] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [showError, setShowError] = useState("")

    const [id, setId] = useState("")
    const [email, setEmail] = useState("")
    const [nome, setNome] = useState("")
    const [volumeRichiesto, setVolumeRichiesto] = useState("")

    useEffect(() => {
        let url = window.location.href;
        const params = new URLSearchParams(window.location.search)
        if (params.has("token")) {
            login(params.get("token"))
            let newUrl = url.split('?')[0];
            window.history.pushState("", "", newUrl);
        } else setTokenAssente(true)

    }, [])


    async function login(token) {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token
            })
        };

        let url = process.env.REACT_APP_FETCH_URL + "auth/loginWP";
        try {
            let res = await fetch(url, requestOptions);
            if (res.status === 200) {
                res.json().then(
                    result => {
                        setShowError(false)
                        setEmail(result.email)
                        setId(result.id)
                        setNome(result.nome)
                        setVolumeRichiesto(result.volumeRichiesto)
                    }
                )
            } else {
                res.json().then(
                    result => {
                        setErrorMsg(result.data[0].msg)
                        setShowError(true)
                    }
                )
            }
        } catch (error) { }
    }
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Paper elevation={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 5 }}>
                {tokenAssente ? (
                    <Typography><span style={{ color: 'red' }}>Errore:</span>Nessun token inserito</Typography>
                ) : (
                    showError ? (
                        <Typography><span style={{ color: 'red' }}>Errore:</span> {errorMsg}</Typography>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography><b>id:</b> {id}</Typography>
                            <Typography><b>Email:</b> {email}{email == null && ("null")}</Typography>
                            <Typography><b>Nome:</b> {nome} {nome == null && ("null")}</Typography>
                            <Typography><b>Volume richiesto:</b> {volumeRichiesto}</Typography>
                        </Box>
                    )
                )}
            </Paper>
        </Box>
    )
}