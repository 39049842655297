import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const QuestionarioAPI = {
    loginWp: async function (obj, cancel = false) {
        const response = await api.request({
            url: `auth/loginWP`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(obj),
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    isLoggedIn: async function (cancel = false) {
        const response = await api.request({
            url: `utente/isLoggedIn`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    logout: async function (cancel = false) {
        const response = await api.request({
            url: `utente/logout`,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getQuestionarioGratuito: async function (id, cancel = false) {
        const response = await api.request({
            url: `questionario/getGratuito/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    questionarioByISBN: async function (isbn, cancel = false) {
        const response = await api.request({
            url: `questionario/list/byISBN/${isbn}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPurchased: async function (cancel = false) {
        const response = await api.request({
            url: `questionario/list/purchased`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    archiveGratuito: async function (obj, cancel = false) {
        const response = await api.request({
            url: `questionario/archive/gratuito`,
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },


}
const cancelApiObject = defineCancelApiObject(QuestionarioAPI)
