import * as React from 'react';
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';


export default function SessioneScadutaPage(props) {



    useEffect(() => {
        let cookiesAcceptedTmp = false;
        if (localStorage.getItem("cookieAccepted"))
            cookiesAcceptedTmp = (localStorage.getItem("cookieAccepted"));

        localStorage.clear();
        localStorage.setItem("cookieAccepted", cookiesAcceptedTmp)
        setTimeout(() => {
            window.location.href = "https://edizioni.simone.it/il-mio-account/?redirect_to=https://simulatori.simone.it";
        }, 1000)
    });

    return (
        <Box height={{ xs: 'unset', md: '100%' }} sx={{
            maxWidth: '100vw', minHeight: '100vh',
            background: 'linear-gradient(145deg, rgba(24,102,171,1) 0%, rgba(0,53,99,1) 80%)'
        }}>
            <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Card>
                    <CardContent>
                        <Typography variant="h3">Sessione scaduta</Typography>
                        <Typography variant="body1">Sembra che la tua sessione sia scaduta.<br></br> A breve verrai reindirizzato sul sito <a href="https://edizioni.simone.it/il-mio-account/?redirect_to=https://simulatori.simone.it"><b>edizioni.simone.it</b></a> dove potrai accedere ad una nuova sessione...</Typography>
                        <Box display={"flex"} justifyContent={"center"} sx={{ mt: 3 }}>
                            <CircularProgress
                                variant="indeterminate"
                                color="secondary"
                                disableShrink
                                sx={{
                                    animationDuration: '550ms',
                                    mb: 1,
                                    left: 0,
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                                size={50}
                                thickness={5} />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}