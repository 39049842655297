export function unEscapeHtml(htmlStr) {
    if (htmlStr != null) {
        if (htmlStr.match(/&lt;/g))
            htmlStr = htmlStr.replace(/&lt;/g, "<span>&#60;</span>");

        if (htmlStr.match(/&gt;/g))
            htmlStr = htmlStr.replace(/&gt;/g, "<span>&#62;</span>");

        if (htmlStr.match(/&quot;/g))
            htmlStr = htmlStr.replace(/&quot;/g, "\"");

        if (htmlStr.match(/&#39;/g))
            htmlStr = htmlStr.replace(/&#39;/g, "\'");

        if (htmlStr.match(/&amp;/g))
            htmlStr = htmlStr.replace(/&amp;/g, "&");


        return htmlStr;
    } else return ''
}

export function cleanTitle(title) {
    title = unEscapeHtml(title);
    title = title.replaceAll("src=\"http://sandbox2.itlogix.it/", `src="${process.env.REACT_APP_IMAGES_PREFIX}`)
        .replaceAll("src=\"/", `src="${process.env.REACT_APP_IMAGES_PREFIX}`)
        .replaceAll("src=\"", `src="${process.env.REACT_APP_IMAGES_PREFIX}`);

    title = title.replaceAll("<table>", '<table style="border-collapse: collapse; width:100%;" border="1">')
        .replaceAll("<td></td>", '');

    return title;
}
export function cleanChoice(choiceText) {
    return choiceText.replaceAll("src=\"http://sandbox2.itlogix.it/", `src="${process.env.REACT_APP_IMAGES_PREFIX}`)
        .replaceAll("src=\"/", `src="${process.env.REACT_APP_IMAGES_PREFIX}`)
        .replaceAll("src=\"", `src="${process.env.REACT_APP_IMAGES_PREFIX}`);
}

export function getObjQuestionarioCompilato(sezioni, sezioniLocation, selectedRisposte){
    let questionCompilatoArray = [];
    let tmpSezioni = []; //Per evitare che vengano messe sezioni senza quiz
    let numberQuiz = 0;

    for (let i = 0; i < sezioni.length; i++) {
        if (sezioni[i].quiz.length > 0) {
            let tmpSezione = sezioni[i]
            tmpSezione.tempMin = sezioniLocation[i].tempMin;
            tmpSezione.tempMax = sezioniLocation[i].tempMax;
            tmpSezione.puntiOmessa = sezioniLocation[i].puntiOmessa;
            tmpSezione.puntiCorretta = sezioniLocation[i].puntiCorretta;
            tmpSezione.puntiErrata = sezioniLocation[i].puntiErrata;
            let titleTmp = '';
            for (let j = 0; j < tmpSezione.quiz.length; j++) {

                //pulizia domanda
                titleTmp = tmpSezione.quiz[j].title;
                titleTmp = unEscapeHtml(titleTmp);
                if (titleTmp.includes("src=\"http://sandbox2.itlogix.it/")) {
                    titleTmp = titleTmp.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                }
                else if (titleTmp.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                    titleTmp = titleTmp.replaceAll("src=\"\/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                else titleTmp = titleTmp.replaceAll("src=\"", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)

                if (titleTmp.includes("<table>")) {
                    titleTmp = titleTmp.replaceAll("<table>", '<table style="border-collapse: collapse; width:100 %;" border="1">')
                }
                if (titleTmp.includes("<td></td>"))
                    titleTmp = titleTmp.replaceAll("<td></td>", '')

                tmpSezione.quiz[j].title = titleTmp;

                //pulizia risposte
                for (let x = 0; x < tmpSezione.quiz[j].choices.length; x++) {
                    let tmpChoice = tmpSezione.quiz[j].choices[x].text;
                    tmpChoice = unEscapeHtml(tmpChoice);
                    if (tmpChoice.includes("src=\"http://sandbox2.itlogix.it/")) {
                        tmpChoice = tmpChoice.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                    }
                    else if (tmpChoice.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                        tmpChoice = tmpChoice.replaceAll("src=\"\/", "class=\"zoomImage\"src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                    } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                    else tmpChoice = tmpChoice.replaceAll("src=\"", "class=\"zoomImage\" src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                    tmpSezione.quiz[j].choices[x].text = tmpChoice;
                }

                questionCompilatoArray.push({
                    "quizId": tmpSezione.quiz[j].quizId,
                    "type": tmpSezione.quiz[j].type,
                    "title": tmpSezione.quiz[j].title,
                    "choices": tmpSezione.quiz[j].choices,
                    "choicesOrder": tmpSezione.quiz[j].choicesOrder,
                    "correctAnswer": tmpSezione.quiz[j].correctAnswer,
                    "givenAnswer": selectedRisposte.length > 0 ? selectedRisposte : [],
                    "puntiErrata": tmpSezione.puntiErrata !== undefined ? tmpSezione.puntiErrata : -1,
                    "puntiOmessa": tmpSezione.puntiOmessa !== undefined ? tmpSezione.puntiOmessa : -0,
                    "puntiCorretta": tmpSezione.puntiCorretta !== undefined ? tmpSezione.puntiCorretta : 1,
                })


            }
            numberQuiz += tmpSezione.quiz.length;
            tmpSezioni.push(tmpSezione)
        }
    }

    return {
        tmpSezioni: tmpSezioni,
        numberQuiz: numberQuiz,
        questionCompilatoArray: questionCompilatoArray
    };
}

