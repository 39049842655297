import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import PauseIcon from '@mui/icons-material/Pause';
import Tooltip from '@mui/material/Tooltip';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export default function NewTimer  ({ typeQuestionario, timer, sezione, changeRemainingTime, onTimeFinished }) {
    const [timePaused, setTimePaused] = useState(false);
    const [remainingTime, setRemainingTime] = useState(timer);
    const [initialTimer, setInitialTimer] = useState(timer);
    const [key, setKey] = useState(0);

    // Update state when timer prop changes
    useEffect(() => {
        setRemainingTime(timer);
        setInitialTimer(timer);
    }, [timer]);

    const convertMinutesToSeconds = (time) => {
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;
        return `${minutes}:${seconds}`;
    };

    const pauseTime = () => {
        if (typeQuestionario === 'Ese') {
            setTimePaused(prev => !prev);
        }
    };

    return (
        <>
            {typeQuestionario === 'Ese' && (
                <Tooltip arrow title='Metti in pausa'>
                    <Box onClick={pauseTime} sx={{ cursor: 'pointer' }}>
                        <CountdownCircleTimer
                            size={100}
                            key={key}
                            duration={timer}
                            colors={["#003A6C"]}
                            trailColor={"#196BB4"}
                            strokeWidth={12}
                            isPlaying={!timePaused}
                            initialRemainingTime={initialTimer}
                            onUpdate={(time) => {
                                setRemainingTime(time);
                                changeRemainingTime(time);
                            }}
                            onComplete={() => {
                                onTimeFinished();
                                return { shouldRepeat: false };
                            }}
                        >
                            {({ remainingTime }) => (
                                <div>
                                    {!timePaused ? (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography sx={{ color: 'white', cursor: 'pointer' }} variant='h6'>
                                                {convertMinutesToSeconds(remainingTime)}
                                            </Typography>
                                            <PauseIcon sx={{ color: 'white', fontSize: '1rem' }} />
                                        </Box>
                                    ) : (
                                        <PauseIcon sx={{ color: 'white' }} />
                                    )}
                                </div>
                            )}
                        </CountdownCircleTimer>
                    </Box>
                </Tooltip>
            )}
            {typeQuestionario === 'Sim' && (
                <Tooltip arrow title='Nella modalità simulazione non puoi sospendere la prova'>
                    <Box>
                        <CountdownCircleTimer
                            size={100}
                            key={key}
                            duration={timer}
                            colors={["#003A6C"]}
                            trailColor={"#196BB4"}
                            strokeWidth={12}
                            isPlaying={!timePaused}
                            initialRemainingTime={initialTimer}
                            onUpdate={(time) => {
                                setRemainingTime(time);
                                changeRemainingTime(time);
                            }}
                            onComplete={() => {
                                onTimeFinished();
                                return { shouldRepeat: false };
                            }}
                        >
                            {({ remainingTime }) => (
                                <div>
                                    {!timePaused ? (
                                        <Typography sx={{ color: 'white', cursor: 'context-menu' }} variant='h6'>
                                            {convertMinutesToSeconds(remainingTime)}
                                        </Typography>
                                    ) : (
                                        <PauseIcon sx={{ color: 'white' }} />
                                    )}
                                </div>
                            )}
                        </CountdownCircleTimer>
                    </Box>
                </Tooltip>
            )}

            <Dialog open={timePaused}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 5 }}>
                    <Typography variant="h3">In Pausa</Typography>
                    <Button variant="contained" sx={{ mt: 2, color: 'white', px: 5 }} onClick={pauseTime}>
                        Torna al quiz
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

