import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { StatisticheQuizSvolto } from './statisticheQuizSvolto';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, useMediaQuery } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from "react-router-dom"
import { Timer } from '../components/vistaQuiz/timer';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { cleanChoice, cleanTitle, unEscapeHtml } from '../components/utility';
import { Helmet } from 'react-helmet-async';
import NewPagination from '../components/vistaQuiz/newPagination';
import NewAnswer from '../components/vistaQuiz/newAnswer';
import NewTimer from '../components/vistaQuiz/newTimer';

export default function VistaQuiz(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const abortController = new AbortController();
    const signal = abortController.signal;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [dataInizio, setDataInizio] = useState(0); //Formato timestamp
    const [dataFine, setDataFine] = useState(0)

    const [quizLoaded, setQuizLoaded] = useState(false);

    const [selectedRisposte, setSelectedRisposte] = useState([]);

    const [indexDomanda, setIndexDomanda] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [indexDomandaProgress, setIndexDomandaProgress] = useState(0);
    const [indexSezione, setIndexSezione] = useState(0);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(false);
    const [statisticheDialogOpen, setStatisticheDialogOpen] = useState(false);
    const [sezioni, setSezioni] = useState([]);
    const [questionarioCompilato, setQuestionarioCompilato] = useState([]);

    const [timer, setTimer] = useState(3600);
    const [tempoIniziale, setTempoIniziale] = useState(0);
    const [tempoCompletamento, setTempoCompletamento] = useState(0);
    const [remainingTime, setRemainingTime] = useState(3600);
    const [lastSectionNumber, setLastSectionNumber] = useState(1);

    const [tempoTrascorsoInSezione, setTempoTrascorsoInSezione] = useState(0);
    const [sezioniConTempoMinTrascorso, setSezioniConTempoMinTrascorso] = useState([]);

    const [totalNumberQuiz, setTotalNumberQuiz] = useState(0);
    const [typeQuestionario, setTypeQuestionario] = useState('Sim'); //Lo posso rimuovere
    const [isGratuito, setIsGratuito] = useState(false); //Lo posso rimuovere
    const [errorQuiz, setErrorQuiz] = useState(false);
    const [quizSection, setquizSection] = useState([]);
    const [idQuizCompletati, setIdQuizCompletati] = useState([]);

    const [nextDomandaButtonDisabled, setNextDomandaButtonDisabled] = useState(false);

    const [openEndQuizDialog, setOpenEndQuizDialog] = useState(false);

    const [timeFinishedDialogOpen, setTimeFinishedDialogOpen] = useState(false)

    const [sogliaSufficienza, setSogliaSufficienza] = useState(0);



    useEffect(() => {


        setDataInizio(new Date().getTime());

        if (location.state != null) {
            setIsGratuito(location.state.gratuito)
            if (location.state.prettyLink !== "") {
                let currentUrl = window.location.href;
                let permalink = currentUrl.split("quiz/")[1];
                window.history.pushState({}, 'Questionario', process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/questionario/" + permalink);
            }
            if (location.state.questionario != null) {
                setTypeQuestionario(location.state.type)
                setSogliaSufficienza(location.state.questionario.sogliaSufficienza)
                if (location.state.type === "Ese") {
                    setTotalNumberQuiz(location.state.numeroQuiz)
                }
            }

            if (location.state.gratuito === false || location.state.gratuito === undefined || location.state.gratuito == null)
                parseQuestionario(false);
            else parseQuestionario(true);
        }
        else { //Se location.state è null allora ti rimando alla home
            navigate("/")
        }
        return () => {
            abortController.abort();
        }
    }, []);



    window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
            e.returnValue = '';
        }
        return '';
    };

    useInterval(() => {
        if (sezioni.length > 0) {
            setTempoTrascorsoInSezione(tempoTrascorsoInSezione + 1);
        }
    }, 1000);




    function formatQuiz(quiz, tmpSezione) {
        return {
            quizId: quiz.quizId,
            type: quiz.type,
            title: quiz.title,
            choices: quiz.choices,
            choicesOrder: quiz.choicesOrder,
            correctAnswer: quiz.correctAnswer,
            givenAnswer: selectedRisposte.length > 0 ? selectedRisposte : [],
            puntiErrata: tmpSezione.puntiErrata ?? -1,
            puntiOmessa: tmpSezione.puntiOmessa ?? 0,
            puntiCorretta: tmpSezione.puntiCorretta ?? 1
        };
    }


    //Metodi Utility
    function calculateTempoRestanteSezione() {
        let tempoRestante;
        tempoRestante = (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione;
        if (tempoRestante > 0) return convertNumberToMinutes(tempoRestante)
        else return 0;
    }
    function convertMinutesToSeconds(time) {  /*Converto i secondi in formato MM:SS */
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds
        return (minutes + "." + seconds)
    }
    function convertNumberToMinutes(number) {
        let minutes = Math.floor(number / 60);
        var seconds = number - minutes * 60;
        return minutes + ":" + seconds
    }
    function progressBarPercentage() { /*Barra orizzontale domanda attuale/domande totali */
        if (quizLoaded === true) {
            let current = questionarioCompilato.filter(quiz => quiz.givenAnswer.length > 0).length;
            return Math.round((current * 100) / totalNumberQuiz)
        }
    }
    function getTotalAnswerNumber(sezioniTmp) {
        let quizTotalNumber = 0;
        for (let i = 0; i < sezioniTmp.length; i++) {
            quizTotalNumber += sezioniTmp[i].quiz.length;
        }
        setTotalNumberQuiz(quizTotalNumber);

    }

    function checkForEmptyGivenAnswer() {
        if (!showCorrectAnswer || (showCorrectAnswer && nextQuestion)) {
            nextQuestionCheck(true);
            //checkQuizInSection();
            let userAsnwers = questionarioCompilato.filter(quiz => quiz.givenAnswer.length === 0)
            if (userAsnwers.length > 0) {
                handleDialogOpen();
            } else {
                nextQuestionCheck();
                checkQuizInSection();
            }
        } else {
            setNextQuestion(true);
        }
    }
    function checkQuizInSection() {
        let quizSectionArray = quizSection;
        let checkedId = sezioni[indexSezione].quiz[indexDomanda].quizId; //indexDomanda;
        if (!quizSectionArray.includes(checkedId)) {
            quizSectionArray.push(checkedId);
            setquizSection([...quizSectionArray])
        }

    }
    function changeRemainingTime(remainingTime) {
        let remainingTimeTmp = remainingTime;
        if (sezioni[indexSezione].tempMin > 0) {
            remainingTimeTmp = remainingTimeTmp - (sezioni[indexSezione].tempMax * 60)
            setRemainingTime(remainingTimeTmp)
        } else setRemainingTime(0);
    }
    function onTimeFinished() {
        setTimeFinishedDialogOpen(true)
    }
    function changePage(e) {
        e.preventDefault()
        navigate("/sim", { state: { idQuestionario: location?.state?.idQuestionario ?? -1, idUtente: location?.state?.idUtente ?? 99 } })
    }
    function goBackToQuestionarioGratuito() {
        navigate("/sim/" + location.state.prettyLink)
    }


    //Metodi setter
    function selectRisposta(domanda, index) {
        if (domanda.value !== undefined) {
            let selectedRisposteTmp = selectedRisposte;
            // if (sezioni[indexSezione].quiz[indexDomanda].correctAnswer.length > 1) { //Se ha piu di una risposta corretta
            //     if (!selectedRisposte.includes(domanda.value)) {
            //         selectedRisposteTmp.push(domanda.value)
            //     }
            //     else {
            //         let index = selectedRisposteTmp.indexOf(domanda.value);
            //         selectedRisposteTmp.splice(index, 1);
            //     }
            //     setSelectedRisposte([...selectedRisposteTmp]);
            //     // if (domanda.value != selectedDomanda)
            //     //     setSelectedDomanda(domanda.value)
            //     // else setSelectedDomanda("");
            // } else {
            //     if (!selectedRisposte.includes(domanda.value)) {
            //         selectedRisposteTmp = [domanda.value]
            //     }
            //     else {
            //         selectedRisposteTmp = []
            //     }
            //     setSelectedRisposte([...selectedRisposteTmp]);
            // }

            if (!selectedRisposte.includes(domanda.value)) {
                selectedRisposteTmp = [domanda.value]
            }
            else {
                selectedRisposteTmp = []
            }
            setSelectedRisposte([...selectedRisposteTmp]);
        }
        else {
            if (index != selectedDomanda) {
                let selectedRisposteTmp = selectedRisposte;

                if (!selectedRisposte.includes(index)) {
                    selectedRisposteTmp.push(index)
                }
                else {
                    let indexTmp = selectedRisposteTmp.indexOf(index);
                    selectedRisposteTmp.splice(indexTmp, 1);
                }
                setSelectedRisposte([...selectedRisposteTmp]);

            } else setSelectedRisposte([]);
            //         setSelectedDomanda(index);
            //     else setSelectedDomanda("");
        }
    }
    function setCorrectAnswer() {
        setShowCorrectAnswer(!showCorrectAnswer);
    }

    //Metodi dialog
    function handleDialogOpen() {
        setOpenEndQuizDialog(true);
    };
    function handleDialogClose() {
        setOpenEndQuizDialog(false);
    };

    //Metodi bottoni
    function nextQuestionCheck(skipCheck) { /*Controlla se è abilitata l'impostazione per controllare la risposta corretta o meno */
        if (showCorrectAnswer === false || currentPage === questionarioCompilato.length - 1) {
            nextQuestionMethod(skipCheck);
        } else setNextQuestion(true);

        if (showCorrectAnswer === true && nextQuestion === true && currentPage !== questionarioCompilato.length - 1) {
            {
                setNextDomandaButtonDisabled(true);
                nextQuestionMethod();
            }
        }
    }

    function nextQuestionMethod(skipCheck) {
        const idQuizArray = [...idQuizCompletati, sezioni[indexSezione].quiz[indexDomanda].quizId];
        const questionarioCompilatoTmp = [...questionarioCompilato];
        const currentQuiz = sezioni[indexSezione].quiz[indexDomanda];
        const indexofQuestion = questionarioCompilatoTmp.findIndex(quiz => quiz.quizId === currentQuiz.quizId);

        questionarioCompilatoTmp[indexofQuestion] = {
            ...questionarioCompilatoTmp[indexofQuestion],
            quizId: currentQuiz.quizId,
            type: currentQuiz.type,
            title: currentQuiz.title,
            choices: currentQuiz.choices,
            choicesOrder: currentQuiz.choicesOrder,
            correctAnswer: currentQuiz.correctAnswer,
            givenAnswer: selectedRisposte.length > 0 ? selectedRisposte : [],
            puntiErrata: currentQuiz.puntiErrata ?? -1,
            puntiOmessa: currentQuiz.puntiOmessa ?? 0,
            puntiCorretta: currentQuiz.puntiCorretta ?? 1,
        };

        setIdQuizCompletati(idQuizArray);
        setQuestionarioCompilato(questionarioCompilatoTmp);

        if (indexDomanda + 1 < sezioni[indexSezione].quiz.length) {
            const newIndex = indexDomanda + 1;
            if (!idQuizArray.includes(currentQuiz.quizId)) {
                setIndexDomandaProgress(indexDomandaProgress + 1);
            }
            setIndexDomanda(newIndex);
            const nextQuiz = questionarioCompilato.find(q => q.quizId === sezioni[indexSezione].quiz[newIndex].quizId);
            setSelectedRisposte(nextQuiz ? nextQuiz.givenAnswer : []);
            setNextQuestion(false);
        } else {
            if (indexSezione < sezioni.length - 1) {
                const newIndexSezione = indexSezione + 1;
                setSezioniConTempoMinTrascorso([...sezioniConTempoMinTrascorso, indexSezione]);
                setTempoTrascorsoInSezione(0);
                setIndexSezione(newIndexSezione);
                setIndexDomanda(0);
                const nextQuiz = questionarioCompilato.find(q => q.quizId === sezioni[newIndexSezione].quiz[0].quizId);
                setSelectedRisposte(nextQuiz ? nextQuiz.givenAnswer : []);
                setNextQuestion(false);
                if (!idQuizArray.includes(currentQuiz.quizId)) {
                    setIndexDomandaProgress(indexDomandaProgress + 1);
                }
                const lastSectionNumber = sezioni.slice(0, newIndexSezione + 1).reduce((acc, sezione) => acc + sezione.quiz.length, 0);
                setLastSectionNumber(lastSectionNumber);
            } else {
                const tempoCompletamentoTmp = convertMinutesToSeconds(tempoIniziale - timer);
                if (!idQuizArray.includes(currentQuiz.quizId)) {
                    setIndexDomandaProgress(indexDomandaProgress + 1);
                }
                if (!skipCheck) {
                    setDataFine(new Date().getTime());
                    setStatisticheDialogOpen(true);
                }
                setTempoCompletamento(tempoCompletamentoTmp);
            }
        }

        setCurrentPage(currentPage + 1);
        if (timeFinishedDialogOpen) {
            setTimeFinishedDialogOpen(false);
        }

        if (showCorrectAnswer && nextQuestion) {
            setTimeout(() => {
                setNextDomandaButtonDisabled(false);
            }, 500);
        }
    }


    function nextSezioneTimeEnded() {
        const indexSezioneSuccessiva = indexSezione + 1;
        if (indexSezione < sezioni.length - 1) { // Se non è l'ultima sezione
            const sezioniTmp = [...sezioni];
            sezioniTmp[indexSezione].remainingTime = 0;
            setSezioni(sezioniTmp);
            setIndexSezione(indexSezioneSuccessiva);
            setIndexDomanda(0);

            const lastSectionNumber = sezioni.slice(0, indexSezioneSuccessiva + 1).reduce((acc, sezione) => acc + sezione.quiz.length, 0);
            setLastSectionNumber(lastSectionNumber);

            const tmpContatore = sezioni.slice(0, indexSezioneSuccessiva).reduce((acc, sezione) => acc + sezione.quiz.length, 0);
            setCurrentPage(tmpContatore);
            setTimeFinishedDialogOpen(false);
        } else { // Se è l'ultima sezione
            setDataFine(new Date().getTime());
            setStatisticheDialogOpen(true);
        }
        setTimeFinishedDialogOpen(false);
    }

    //Metodi pagination
    function onPageChange(pageNumber) {
        setCurrentPage(pageNumber);
     
        let tmpContatore = 0;
        for (let i = 0; i < sezioni.length; i++) {
            for (let j = 0; j < sezioni[i].quiz.length; j++) {
                if (tmpContatore === pageNumber) {
                    if (indexSezione !== i) { // se c'è un cambio di sezione
                        const sezioniTmp = [...sezioni];
                        sezioniTmp[indexSezione].remainingTime = remainingTime;
                        setSezioni(sezioniTmp);
                    }

                    setIndexSezione(i);
                    setIndexDomanda(j);

                    const quizIdTmp = sezioni[i].quiz[j].quizId;
                    const indexQuestionarioCompilato = questionarioCompilato.findIndex(quiz => quiz.quizId === quizIdTmp);

                    if (!isGratuito) {
                        if (typeQuestionario === 'Ese') {
                            if(!idQuizCompletati.includes(quizIdTmp)) setNextQuestion(false);
                            else if (idQuizCompletati.includes(quizIdTmp) && showCorrectAnswer && questionarioCompilato[indexQuestionarioCompilato]?.givenAnswer.length > 0) setNextQuestion(true);
                        }
                    }

                    const givenAnswer = questionarioCompilato[indexQuestionarioCompilato]?.givenAnswer;
                    setSelectedRisposte(Array.isArray(givenAnswer) ? givenAnswer : [givenAnswer]);

                    return;
                }
                tmpContatore++;
            }
        }
    }
    function onPreviousPage() {
        onPageChange(currentPage - 1)
    }
    function onNextPage(skipChangePage) {
        setNextQuestion(false);
        if (skipChangePage === undefined || skipChangePage === false)
            onPageChange(currentPage + 1)
    }


    async function parseQuestionario(isGratuito) {
        let partiListTmp = [];
        let data;
        let timerTmp = 0;

        // Calcolo tempo in base al tipo di questionario
        for (let i = 0; i < location.state.parti.length; i++) {
            if (location.state.parti[i].quizSelezionati > 0) {
                if (location.state.type === 'Ese') {
                    timerTmp += location.state.parti[i].quizSelezionati * 60;
                } else {
                    timerTmp += location.state.parti[i].tempMax * 60;
                }
            }
        }
        setTimer(timerTmp);

        for (let parte of location.state.parti) {
            if (parte.quizSelezionati > 0) {
                let parteTmp = {
                    idParte: parte.idParte,
                    numQuiz: location.state.type === 'Sim' ? parte.quizDisponibili : parte.quizSelezionati,
                    tempMin: parseInt(parte.tempMin),
                    tempMax: parseInt(parte.tempMax),
                    remainingTime: parseInt(parte.tempMax)
                };

                if (location.state.type === 'Seq') {
                    parteTmp.offset = parte.offset;
                    parteTmp.idVolume = parte.idVolume;
                }

                partiListTmp.push(parteTmp);
            }
        }

        data = {
            type: location.state.type,
            id: location.state.idQuestionario,
            parti: location.state.type === 'Ese' || location.state.type === 'Seq' ? partiListTmp : undefined
        };

        if (location.state.riprovaSbagliati !== undefined) {
            data.riprovaSbagliati = location.state.riprovaSbagliati;
        }

        const urlParse = isGratuito ? 'parseGratuito' : 'parse';
        const requestOptions = {
            signal: signal,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch(process.env.REACT_APP_FETCH_URL + `questionario/${urlParse}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    let questionCompilatoArray = [];
                    let tmpSezioni = [];
                    let numberQuiz = 0;

                    for (let i = 0; i < result.data.sezione.length; i++) {
                        if (result.data.sezione[i].quiz.length > 0) {
                            let tmpSezione = result.data.sezione[i];
                            let locationParte = location.state.parti[i];

                            tmpSezione.tempMin = locationParte.tempMin;
                            tmpSezione.tempMax = locationParte.tempMax;
                            tmpSezione.puntiOmessa = locationParte.puntiOmessa;
                            tmpSezione.puntiCorretta = locationParte.puntiCorretta;
                            tmpSezione.puntiErrata = locationParte.puntiErrata;

                            tmpSezione.quiz.forEach(quiz => {
                                quiz.title = cleanTitle(quiz.title);
                                quiz.choices = quiz.choices.map(choice => ({
                                    ...choice,
                                    text: cleanChoice(choice.text)
                                }));
                                questionCompilatoArray.push(formatQuiz(quiz, tmpSezione));
                            });

                            numberQuiz += tmpSezione.quiz.length;
                            tmpSezioni.push(tmpSezione);
                        }
                    }

                    if (numberQuiz > 0) {
                        setSezioni([...tmpSezioni]);
                        getTotalAnswerNumber(result.data.sezione);
                        setSogliaSufficienza(result?.data?.sogliaSufficienza ?? 0);
                        setQuestionarioCompilato([...questionCompilatoArray])
                        setTempoIniziale(tmpSezioni[0].tempMax || 60);
                        setErrorQuiz(false);
                        setQuizLoaded(true);
                        setLastSectionNumber(tmpSezioni[0].quiz.length);
                    } else {
                        setQuizLoaded(true);
                        setErrorQuiz(true);
                    }
                } else {
                    setErrorQuiz(true);
                }
            });
    }


    return (
        <Box>
            <Helmet>
                <title>{location?.state?.questionario?.nome ?? "Questionario"}</title>
            </Helmet>
            <Box height={{ xs: 'unset', md: '100%' }} sx={{
                maxWidth: '100vw', minHeight: '100vh',
                background: 'linear-gradient(145deg, rgba(24,102,171,1) 0%, rgba(0,53,99,1) 80%)'
            }}>

                {totalNumberQuiz > 0 ? (
                    quizLoaded === true && (
                        <Box sx={{ height: '100%' }}>
                            {errorQuiz === false ? (
                                <Container sx={{
                                    px: { xs: 0, md: 2, xl: 'unset' },
                                    display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'center',
                                    height: '100%'
                                }} maxWidth="lg">
                                    <Box px={{ xs: 2, md: 0 }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                                        <Grid container spacing={2} style={{ marginTop: '5px' }}>  
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}> 
                                                <Button variant="text" sx={{ color: 'white' }} startIcon={<ArrowBackIcon />} onClick={() => { isGratuito ? changePage() : goBackToQuestionarioGratuito() }}>
                                                    <Typography variant="subtitle2">
                                                        {isMobile ? "Indietro" : "Torna alla selezione dei simulatori"}
                                                    </Typography>
                                                </Button>
                                            </Grid>

                                            <Grid item xs={5} sm={4} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>  {/*Timer*/}
                                                <NewTimer timer={timer} sezione={sezioni[indexSezione]} typeQuestionario={typeQuestionario}
                                                    changeRemainingTime={changeRemainingTime} onTimeFinished={onTimeFinished} />
                                            </Grid>

                                            <Grid item xs={isMobile ? 3 : 4} sx={{ display: "flex", justifyContent: 'end', alignItems: 'center', mt: { xs: 2, md: 0 } }}> {/*Logo*/}
                                                <img style={{ maxWidth: { xs: '90%', md: '35%' } }} draggable="false" unselectable="on" alt="Logo Simone Edizioni" loading="lazy" src={"https://edizioni.simone.it/wp-content/uploads/2022/02/logo-simone-2022.png"} />
                                            </Grid>
                                        </Grid>

                                        <NewAnswer questionarioSelezionatoID={location?.state?.idQuestionario ?? -1} domanda={sezioni[indexSezione].quiz[indexDomanda]} typeQuestionario={typeQuestionario} nomeParte={sezioni[indexSezione].nomeParte} showCorrectAnswer={showCorrectAnswer}
                                            nextQuestion={nextQuestion} selectDomanda={selectRisposta} selectedRisposte={selectedRisposte} indexDomanda={indexDomanda} isGratuito={props.isGratuito} />

                                        <Grid container style={{ margin: '50px 0px 50px 0px' }}> {/*Bottoni conferma/impostazioni e progress bar */}
                                            {(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione)) && (
                                                <Grid item xs={12} sx={{ my: 2, textAlign: 'end' }}>
                                                    <Typography variant="subtitle1" sx={{ color: 'white' }}>
                                                        Prima di proseguire alla sezione successiva è necessario attendere &nbsp;
                                                        {calculateTempoRestanteSezione()}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} md={4} style={{ position: "relative" }}>
                                                <Typography variant="subtitle2" sx={{top:'-1px', textAlign: 'center', color: 'white', position: 'absolute', zIndex: 10, left: '47%', lineHeight: 'unset' }}>
                                                    {questionarioCompilato.filter(quiz => quiz.givenAnswer.length > 0).length}/{totalNumberQuiz}
                                                </Typography>
                                                <BorderLinearProgress variant="determinate" value={progressBarPercentage()} />
                                            </Grid>

                                            <Grid item xs={12} md={8} mt={{ xs: 2, md: 0 }} justifyContent={{ xs: 'space-between', md: 'end' }} sx={{ display: 'flex', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
                                                {typeQuestionario === 'Ese' && ( //BOTTONE IMPOSTAZIONI
                                                    <Button size={isMobile ? "small" : "medium"} variant="text" fullWidth={isMobile} sx={{ mr: 3, mb: { xs: 1, md: 0 }, color: 'white' }} onClick={setCorrectAnswer}>
                                                        Mostra risposta corretta  {showCorrectAnswer ? <CheckBoxIcon sx={{ ml: 1 }} /> : <CheckBoxOutlineBlankIcon sx={{ ml: 1 }} />}
                                                    </Button>
                                                )}

                                                {currentPage < questionarioCompilato.length - 1 && (
                                                    <Button size={isMobile ? "small" : "medium"} color="success" variant="contained" disabled={((indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione))
                                                        || (nextDomandaButtonDisabled && showCorrectAnswer))} onClick={() => { nextQuestionCheck(); checkQuizInSection() }}>
                                                        {(showCorrectAnswer === true && !isGratuito) && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Prosegui'
                                                            )
                                                        )}
                                                        {showCorrectAnswer === false && (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                'Conferma e vai alla sezione successiva'
                                                            ) : (
                                                                'Conferma e prosegui'
                                                            )
                                                        )}
                                                    </Button>
                                                )}

                                                {currentPage >= questionarioCompilato.length - 1 && (
                                                    <Button size={isMobile ? "small" : "medium"} color="success" disabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && typeQuestionario === 'Ese'}
                                                        variant="contained" onClick={checkForEmptyGivenAnswer}>
                                                        {(showCorrectAnswer === true && !isGratuito) ? (
                                                            indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                nextQuestion === false ? 'Visualizza risposta' : 'Concludi'
                                                            ) : (
                                                                nextQuestion === false ? 'Conferma e visualizza risposta' : 'Concludi'
                                                            )
                                                        ) : (
                                                            "Concludi"
                                                        )}
                                                    </Button>
                                                )}
                                            </Grid>
                                            {/* <Button onClick={debugEndTime} variant="contained">Finisci tempo (Sezione successiva)</Button> */}
                                            <NewPagination
                                                quizInSection={sezioni[indexSezione].quiz}
                                                lastSectionNumber={lastSectionNumber - 1}
                                                questionarioCompilato={questionarioCompilato}
                                                currentPage={currentPage}
                                                goToNextQuiz={onNextPage}
                                                goToPreviousQuiz={onPreviousPage} onPageChange={onPageChange}
                                                nOfQuiz={totalNumberQuiz}
                                                nextArrowDisabled={indexDomanda === (sezioni[indexSezione].quiz.length - 1) && typeQuestionario === 'Ese'}
                                            />
                                        </Grid>
                                    </Box>
                                </Container>
                            ) : (
                                <Container sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    height: '100%'
                                }} maxWidth="lg">
                                    <Typography sx={{ color: 'white', textAlign: 'center' }} variant="h3">C'è stato un errore durante la generazione del quiz</Typography>
                                    {isGratuito === false && (
                                        <Button variant="text" sx={{ color: 'white' }} startIcon={<ArrowBackIcon />} onClick={changePage}>
                                            <Typography variant="subtitle2" display={{ xs: 'flex', md: 'none' }}>
                                                {isMobile ? "Indietro" : "Torna alla selezione dei simulatori"}   Indietro
                                            </Typography>
                                        </Button>
                                    )}
                                </Container>
                            )}
                        </Box>
                    )
                ) : (
                    quizLoaded === true ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95vh', justifyContent: 'center' }}>
                            <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>Il simulatore non ha nessun quiz associato.</Typography>
                            {!isGratuito ? (
                                <Button variant="text" sx={{ mr: 3, color: 'white' }} startIcon={<ArrowBackIcon />} onClick={changePage}>
                                    <Typography variant="subtitle2">
                                        {isMobile ? "Indietro" : "Clicca qui per tornare indietro"}
                                    </Typography>
                                </Button>
                            ) : (
                                <Typography variant="subtitle2" sx={{ color: 'white', textAlign: 'center', mt: 1 }}>Riprova tra qualche minuto</Typography>
                            )}

                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95vh', justifyContent: 'center' }}>
                            <CircularProgress
                                variant="indeterminate"
                                color="secondary"
                                disableShrink
                                sx={{
                                    animationDuration: '550ms',
                                    mb: 1,
                                    left: 0,
                                    [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                    },
                                }}
                                size={40}
                                thickness={4} />
                            <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>Caricamento...</Typography>
                        </Box>
                    )
                )}

                <Dialog open={statisticheDialogOpen} fullScreen>
                    <DialogContent>
                        <StatisticheQuizSvolto dataInizio={dataInizio} dataFine={dataFine} tempo={tempoCompletamento} questionario={questionarioCompilato} isGratuito={isGratuito} nomeQuestionario={location?.state?.questionario?.nome ?? "Questionario"}
                            idVolume={location?.state?.questionario?.idVolume ?? -1} idUtente={location?.state?.idUtente ?? 99} idQuestionario={location?.state?.idQuestionario ?? -1} type={typeQuestionario} sogliaSufficienza={sogliaSufficienza} />
                    </DialogContent>
                </Dialog>

                <Dialog open={openEndQuizDialog}>
                    <DialogContent>
                        <Typography variant="body1">
                        {questionarioCompilato.filter(quiz => quiz.givenAnswer.length === 0).length > 0 ? (
                           <span> Ci sono <b>{questionarioCompilato.filter(quiz => quiz.givenAnswer.length === 0).length}</b> risposte omesse, concludere comunque {typeQuestionario === "Sim" ? "la simulazione" : "l'esercitazione"}?</span>
                        ):(
                            <span>Vuoi concludere {typeQuestionario === "Sim" ? "la simulazione" : "l'esercitazione"}?</span>
                        )}
                            </Typography>
                        <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
                            <Button onClick={handleDialogClose} sx={{ mr: 2 }}>Annulla</Button>
                            <Button onClick={() => { handleDialogClose(); nextQuestionCheck(); checkQuizInSection() }} autoFocus color="success" variant='contained'>
                                Concludi simulatore
                            </Button>
                        </Box>
                    </DialogContent>

                </Dialog>



                {!statisticheDialogOpen && (
                    <Dialog open={timeFinishedDialogOpen}>
                        <DialogContent>
                            <Typography>Il tempo a disposizione per rispondere alle domande della sezione selezionata è concluso.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={nextSezioneTimeEnded}>Prosegui</Button>
                        </DialogActions>
                    </Dialog>
                )}

            </Box>
        </Box >
    )
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 18,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#196BB4',
        boxShadow: '0px 9px 16px 0px rgb(0 0 0 / 19%)',

    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 8,
        backgroundColor: '#35aa11',

    },
}));


function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}
