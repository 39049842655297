import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Card, CardActionArea, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function NewPagination(props) {
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    useEffect(() => {
        const boxContainer = document.querySelector('.scroll-container');
        if (boxContainer) {
            boxContainer.addEventListener('scroll', handleScroll);
            handleScroll();
        }
        return () => {
            if (boxContainer) {
                boxContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        scrollToPageNumber(props.currentPage + 1, 'nearest');
    }, [props.currentPage])

    function handleScroll() {
        const boxContainer = document.querySelector('.scroll-container');
        if (boxContainer) {
            const scrollLeft = boxContainer.scrollLeft;
            const scrollWidth = boxContainer.scrollWidth;
            const clientWidth = boxContainer.clientWidth;

            setIsAtStart(scrollLeft === 0);
            setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);  // Se lo scroll è alla fine
        }
    }

    function goToPreviousQuiz() {
        const firstNonVisibleIndex = findFirstNonVisiblePage("left");
        if (firstNonVisibleIndex !== -1) {
            scrollToPageNumber(firstNonVisibleIndex, "end");
        }
    }

    function goToNextQuiz() {
        const firstNonVisibleIndex = findFirstNonVisiblePage("right");
        if (firstNonVisibleIndex !== -1) {
            scrollToPageNumber(firstNonVisibleIndex, "start");
        }
    }

    function changePage(n) {
        scrollToPageNumber(n, 'nearest');
        if (props.onPageChange) props.onPageChange(n);
    }

    function scrollToPageNumber(nPage, inline) {
        const element = document.getElementById("page-" + nPage);
        if (element) {
            element.scrollIntoView({ block: 'center', inline: inline }); //behavior: 'smooth',
            // window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function findFirstNonVisiblePage(direction) {
        const boxContainer = document.querySelector('.scroll-container');
        const paginationContainer = document.querySelector('.scroll-container');
        if (boxContainer) {
            const pages = Array.from(paginationContainer.children);
            const containerScrollLeft = boxContainer.scrollLeft;
            const containerWidth = boxContainer.clientWidth; // Larghezza visibile del contenitore

            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                const pageLeft = page.offsetLeft; // Posizione dell'elemento rispetto al contenitore scrollabile
                const pageRight = pageLeft + page.offsetWidth; // Fine dell'elemento

                if (direction === "left") {
                    if (pageRight < containerScrollLeft) {
                        continue; // Elemento completamente a sinistra, continua a cercare
                    } else {
                        return i;
                    }
                }

                if (direction === "right") {
                    if (pageLeft >= containerScrollLeft + containerWidth) {
                        return i;
                    }
                }
            }
        }
        return -1;
    }



    return (
        <Card sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%', p: 1, mt: 2 }}>
            <IconButton onClick={goToPreviousQuiz} disabled={isAtStart}>
                <ArrowBack />
            </IconButton>
            <Box className="scroll-container" sx={{ display: "flex", alignItems: "center", overflowX: "scroll", p: 2 }}>
                {props.questionarioCompilato.map((answer, index) => (
                    <Box key={index} id={"page-" + index} sx={{
                        minWidth: { xs: '38px', md: '53px' }, minHeight: { xs: '35px', md: '50px' }, display: "flex", alignItems: 'center', justifyContent: 'center',
                        border: '2px solid',
                        background: props.currentPage === index ? '#08467c' : 'white',
                        borderColor: answer.givenAnswer.length === 0 ? (props.currentPage === index ? '#08467c' : 'white') : '#08467c',
                        color: props.currentPage === index ? 'white' : 'black', borderRadius: '100%',
                        boxSizing: 'border-box', mr: { xs: index < (props.questionarioCompilato.length - 1) ? 1 : 0, md: index < (props.questionarioCompilato.length - 1) ? 2 : 0 }
                    }}>
                        <CardActionArea disabled={index > props.lastSectionNumber} onClick={() => changePage(index)}
                            sx={{ opacity: index > props.lastSectionNumber ? 0.3 : 1, width: '100%', height: { xs: '35px', md: '50px' }, borderRadius: '100%' }} >
                            <Typography sx={{ fontWeight: answer.givenAnswer.length === 0 ? 'unset' : 'bold' }} textAlign={"center"}>{index + 1}</Typography>
                        </CardActionArea>
                    </Box>
                ))}
            </Box>
            <IconButton onClick={goToNextQuiz} disabled={isAtEnd}>
                <ArrowForward />
            </IconButton>
        </Card>
    );
}
