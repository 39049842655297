import { Fragment, useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import QuizIcon from '@mui/icons-material/Quiz';
import StartIcon from '@mui/icons-material/Start';

export default function NewSezione(props) {

    const quizSelezionatiRef = useRef();
    const quizDiPartenzaRef = useRef();

    const [sezioneObj, setSezioneObj] = useState(undefined);
    const [numeroQuizSelezionati, setNumeroQuizSelezionati] = useState(0);
    const [quizDiPartenza, setQuizDiPartenza] = useState(0);
    const [maxQuizDiPartenza, setMaxQuizDiPartenza] = useState(0);

    useEffect(() => {
        const sezioneObjTmp = props.sezioneObj;
        setSezioneObj(sezioneObjTmp);
        if (sezioneObjTmp?.numQuiz !== undefined) {
            let nQuizSelezionatiTmp = 0;
            const nQuizParsed = parseInt(sezioneObjTmp?.numQuiz ?? 0);
            const quizDisponobiliParsed = parseInt(sezioneObjTmp?.quizDisponibili ?? 0);
            if (nQuizParsed > quizDisponobiliParsed)
                nQuizSelezionatiTmp = quizDisponobiliParsed;
            else nQuizSelezionatiTmp = sezioneObjTmp?.numQuiz;

            if (quizSelezionatiRef.current != null)
                quizSelezionatiRef.current.value = nQuizslezionatiTmp;
            setNumeroQuizSelezionati(nQuizSelezionatiTmp);
            setMaxQuizDiPartenza( quizDisponobiliParsed);
        }
        //percentualiQuiz
    }, [props.sezioneObj])


    function changeNumeroQuiz(e) {
        const numeroQuizSelezionatiTmp = parseInt(e.target.value);
        if (props.tipoTest === "Seq") {
            let maxPartenzaTmp = sezioneObj.quizDisponibili - numeroQuizSelezionatiTmp;
            let tmpQuizPartenza = quizDiPartenza;
            if (tmpQuizPartenza > maxPartenzaTmp)
                tmpQuizPartenza = maxPartenzaTmp - 1;

            if (numeroQuizSelezionatiTmp < sezioneObj.quizDisponibili) {
                setQuizDiPartenza(tmpQuizPartenza)
                setMaxQuizDiPartenza(maxPartenzaTmp);
                quizDiPartenzaRef.current.value = tmpQuizPartenza;
            } else {
                setQuizDiPartenza(0)
                quizDiPartenzaRef.current.value = 0;
            }
        }

        quizSelezionatiRef.current.value = numeroQuizSelezionatiTmp;

        if (props.changeNumeroQuiz)
            props.changeNumeroQuiz(numeroQuizSelezionatiTmp, props.indexSezione, quizDiPartenza);

        setNumeroQuizSelezionati(numeroQuizSelezionatiTmp);
    }

    function changeNumeroQuizPartenza(e) {
        const quizDiPartenzaTmp = parseInt(e.target.value);
        if (quizDiPartenzaTmp < 0 || isNaN(quizDiPartenzaTmp)) quizDiPartenzaTmp = 0;
        setQuizDiPartenza(quizDiPartenzaTmp);
        quizDiPartenzaRef.current.value = quizDiPartenzaTmp;
        if (props.changeNumeroQuiz)
            props.changeNumeroQuiz(numeroQuizSelezionati, props.indexSezione, quizDiPartenzaTmp);
    }

    function changeNumeroQuizToParent() { //Fixa il lag, dato che questo viene triggherato all onBlur
        if (props.changeNumeroQuiz)
            props.changeNumeroQuiz(numeroQuizSelezionati, props.indexSezione, quizDiPartenza);
    }


    return (
        <Fragment>
            {sezioneObj !== undefined ? (
                <Paper elevation={5} sx={{ height: '100%' }}>
                    <Box sx={{ p: 3, minHeight: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography sx={{ textAlign: 'center' }} variant="body2">
                            <b>{sezioneObj?.nomeParte ?? ""}</b>
                        </Typography>


                        {props.tipoTest === 'Sim' && ( /*Simulazione*/
                            <Typography variant="body1" sx={{ color: '#003370' }}>
                                Numero di quesiti somministrati: <b> {sezioneObj?.numQuiz ?? 0}   </b>
                            </Typography>
                        )}

                        {props.tipoTest === 'Ese' && ( /*Esercitazione*/
                            <Fragment>
                                <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <QuizIcon sx={{ mr: 2, color: '#003370' }} />
                                        <Typography >  Numero di quiz:</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField onBlur={changeNumeroQuiz} inputProps={{ style: { textAlign: 'center' } }}
                                            defaultValue={numeroQuizSelezionati} variant="outlined" inputRef={quizSelezionatiRef} />
                                    </Grid>
                                    <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                        <Typography >di {sezioneObj.quizDisponibili}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider size="small"
                                            value={typeof (numeroQuizSelezionati) !== "number" ? parseInt(numeroQuizSelezionati) : numeroQuizSelezionati}
                                            min={0} max={parseInt(sezioneObj.quizDisponibili)} onChange={changeNumeroQuiz}
                                            onChangeCommitted={changeNumeroQuizToParent} valueLabelDisplay="auto"
                                        />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}

                        {(props.tipoTest === 'Seq') && ( /*Sequenziale*/
                            <Fragment>
                                <Box>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <QuizIcon sx={{ mr: 2, color: '#003370' }} />
                                            <Typography >
                                                Numero di quiz:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField onBlur={changeNumeroQuiz} inputProps={{ style: { textAlign: 'center' } }}
                                                defaultValue={numeroQuizSelezionati} variant="outlined" inputRef={quizSelezionatiRef} />
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: 'end' }}>
                                            <Typography >di {sezioneObj?.quizDisponibili ?? 0}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Slider size="small"
                                            value={typeof (numeroQuizSelezionati) !== "number" ? parseInt(numeroQuizSelezionati) : numeroQuizSelezionati}
                                            min={0} max={parseInt(sezioneObj.quizDisponibili)} onChange={changeNumeroQuiz}
                                            onChangeCommitted={changeNumeroQuizToParent} valueLabelDisplay="auto"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/*Parti dal quiz*/}
                                <Box>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <StartIcon sx={{ mr: 2, color: '#003370' }} />
                                            <Typography >
                                                Parti dal quiz:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField onBlur={changeNumeroQuizPartenza} inputProps={{ style: { textAlign: 'center' } }}
                                                defaultValue={quizDiPartenza} variant="outlined" inputRef={quizDiPartenzaRef} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Slider size="small" value={quizDiPartenza} disabled={numeroQuizSelezionati == sezioneObj.quizDisponibili || numeroQuizSelezionati === 0} min={0} max={maxQuizDiPartenza}
                                                onChange={changeNumeroQuizPartenza} onChangeCommitted={changeNumeroQuizToParent} valueLabelDisplay="auto" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Fragment>
                        )}

                        {(props.tipoTest == 'Seq') && (
                            <Fragment>
                                <Divider style={{ width: "100%" }} sx={{ my: 1 }} />
                                <Typography>
                                    Percentuale di completamento: <b>{(props?.percentualeQuiz !== undefined && props.percentualeQuiz !== null) ? (props.percentualeQuiz > 100 ? 100 : props.percentualeQuiz) : 0}%</b>
                                </Typography>
                            </Fragment>
                        )}

                    </Box>
                </Paper>
            ) : (
                <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: '10px' }} height={150} />
            )}
        </Fragment>
    )
}