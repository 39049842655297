import { Fragment, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import DOMPurify from 'dompurify';
import { CardActionArea, Chip, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Close, Error } from "@mui/icons-material";
import { grey } from '@mui/material/colors';
import Segnalazione from "./segnalazione";

export default function NewAnswer(props) {

    const [openDialogImageZoom, setOpenDialogImageZoom] = useState(false);
    const [urlImageToZoom, setUrlImageToZoom] = useState("false")
    const [isCardDisabled, setIsCardDisabled] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        document.addEventListener("click", function (event) {
            let targetElement = event.target;
            if (targetElement.tagName === 'IMG') {
                let selectable = event.target?.attributes?.unselectable?.value ?? undefined;
                if (selectable === undefined) {
                    setUrlImageToZoom(targetElement.src);
                    setOpenDialogImageZoom(true);
                }
            }
        });
    }, [])

    function selectDomanda(domanda, index) {
        if (!isCardDisabled && props.selectDomanda) {
            setIsCardDisabled(true);
            props.selectDomanda(domanda, index);
            setTimeout(() => setIsCardDisabled(false), 300);
        }
    }
    function changeColorAnswer(choice) {
        const isCorrect = props.domanda.correctAnswer.includes(choice.value);
        const isSelected = props.selectedRisposte.includes(choice.value);

        if (props.showCorrectAnswer) {
            if (props.nextQuestion) {
                return isCorrect ? '#009f07' : (isSelected ? '#d10505' : 'white'); // Verde per corretta, rosso per sbagliata, bianco altrimenti
            }
            return isSelected ? '#171717' : 'white'; // Nero se selezionata, bianco altrimenti
        }
        return isSelected ? '#171717' : 'white';
    }
    function changeColorIndex(choice) {
        const isCorrect = props.domanda.correctAnswer.includes(choice.value);
        const isSelected = props.selectedRisposte.includes(choice.value);

        if (props.showCorrectAnswer) {
            if (props.nextQuestion) {
                return isCorrect ? 'white' : (isSelected ? 'white' : 'black'); // Verde per corretta, rosso per sbagliata, bianco altrimenti
            }
            return isSelected ? 'white' : 'black'; // Nero se selezionata, bianco altrimenti
        }
        return isSelected ? 'white' : 'black';
    }
    function changeColorIndexText(choice) {
        const isCorrect = props.domanda.correctAnswer.includes(choice.value);
        const isSelected = props.selectedRisposte.includes(choice.value);

        if (props.showCorrectAnswer) {
            if (props.nextQuestion) {
                return isCorrect ? "black" : (isSelected ? 'black' : 'white');  // Verde per corretta, rosso per sbagliata, bianco altrimenti
            } else
                return isSelected ? 'black' : 'white';
        }
        return isSelected ? 'black' : 'white';
    }
    function changeColorText(choice) {
        const isCorrect = props.domanda.correctAnswer.includes(choice.value);
        const isSelected = props.selectedRisposte.includes(choice.value);

        if (props.showCorrectAnswer) {
            if (props.nextQuestion) {
                return isSelected || isCorrect ? 'white' : 'black'
            }
            return isSelected ? 'white' : 'black';
        }
        return isSelected ? 'white' : 'black';
    }


    return (
        <>

            <Box sx={{ my: 1, width: '100%' }}> {/*Domanda e n. domanda */}
                {props.domanda.ripresentato && (
                    <Chip sx={{ my: 1, width: { xs: '100%', md: 'fit-content' } }} size='small' color="error" label="Quiz sbagliato frequentemente" icon={<Error />} />
                )}
                <Box sx={{display:"flex", flexDirection: {xs:'column',md:'row'}}}>
                <Typography alignItems="center" sx={{ color: grey[400] }} variant='subtitle2'>
                    Domanda n.{props.indexDomanda + 1}
                    {(props.nomeParte !== '' && props.typeQuestionario === 'Ese') && (' (' + props.nomeParte + ')')}
                </Typography>
                {!props.isGratuito && (
                    <Segnalazione domanda={props.domanda} questionarioId={props.questionarioSelezionatoID} />
                )}
                </Box>
                <Box sx={{ mt: 1, color: grey[50], fontSize: { xs: '1rem', md: '1.5rem' }, fontWeight: 'bold' }} >
                    <div className='titleContainer' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.domanda.title) }}></div>
                </Box>
                {props.domanda.correctAnswer.length > 1 && (
                    <Box sx={{ mt: 1 }}>
                        <Typography color="white" variant="subtitle2">É possibile selezionare più risposte corrette in questa domanda.</Typography>
                    </Box>
                )}
                {props.domanda.title.includes("<img") ? (<Typography sx={{ color: 'white', ml: 1 }} variant="body2">Clicca le immagini per ingrandirle.</Typography>) : null}
            </Box>
            {props.domanda && (
                props.domanda.choices.map((choice, index) => (
                    <Fragment key={index}>
                        {props.domanda.type === "radiogroup" && (//Tipo di domanda TESTO
                            <Grid key={"radio" + index} item xs={12}>
                                <Card
                                    className={`cardDomanda ${(props.showCorrectAnswer === true && props.nextQuestion === true) || props.selectedRisposte.includes(choice.value) ? "" : "showAnswerHoverEffect"}`}
                                    sx={{
                                        mt: 3,
                                        backgroundColor: changeColorAnswer(choice),
                                        '&:hover': {
                                            '.hoverable-text': {
                                                color: 'white',
                                            },
                                        }
                                    }}
                                >
                                    <CardActionArea sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }} onClick={() => selectDomanda(choice, index)}>
                                        <Avatar sx={{ bgcolor: changeColorIndex(choice), m: 1, p: { xs: 1, md: 0 }, width: { xs: '20px', md: '40px' }, height: { xs: '20px', md: '40px' } }}>
                                            <Typography variant={isMobile ? "body2" : "body1"} sx={{ color: changeColorIndexText(choice) }} >
                                                {(index + 10).toString(36).toUpperCase()}
                                            </Typography>
                                        </Avatar>
                                        <Typography className={(props.showCorrectAnswer && props.nextQuestion) ? "" : "hoverable-text"} variant={isMobile ? "body2" : "body1"} sx={{ overflowX: "auto", p: 2, ml: 0, color: changeColorText(choice) }}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(choice.text)
                                            }} >
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )}

                        {props.domanda.type === "imagepicker" && ( //Tipo di domanda IMMAGINI
                            <Grid key={"image" + index} item xs={6} sx={{ p: 2 }}>
                                <Card p={{ xs: 0, md: 2 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: changeColorAnswer(choice) }}
                                    className={`cardDomanda ${(props.showCorrectAnswer === true && props.nextQuestion === true) || props.selectedRisposte.includes(choice.value) ? "" : "showAnswerHoverEffect"}`}
                                    onClick={() => selectDomanda(choice, index)}>
                                    <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choice.text) }} >
                                    </div>
                                    <Box >
                                        <Avatar sx={{ bgcolor: changeColorIndex(choice), my: 2 }}>
                                            <Typography variant={isMobile ? "body2" : "body1"} sx={{ color: changeColorIndexText(choice) }} >
                                                {(index + 10).toString(36).toUpperCase()}
                                            </Typography>
                                        </Avatar>
                                    </Box>
                                </Card>
                            </Grid>
                        )}
                        <Dialog maxWidth="md" fullWidth open={openDialogImageZoom} onClose={() => { setOpenDialogImageZoom(false) }}>
                            <DialogTitle sx={{ p: 0, display: 'flex', justifyContent: 'end' }}>
                                <IconButton onClick={() => { setOpenDialogImageZoom(false) }} >
                                    <Close />
                                </IconButton>
                            </DialogTitle>

                            <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                                <img style={{ width: '100%', maxHeight: '100%' }} src={urlImageToZoom} />
                            </DialogContent>
                        </Dialog>
                    </Fragment>
                ))
            )}
        </>
    )
}