import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cat from '../gatto_404.png'; // with import
import logoBlu from '../edizioni-simone-logo-blu.png'; // with import

export default function ErrorPage(props) {
    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img  draggable="false" style={{ marginLeft: "30px", maxWidth:"40%" }} src={logoBlu}></img>
            <Box sx={{ textAlign: 'center',mt:3 }}>
                <Typography variant="h1" sx={{ fontSize: '15rem!important' }}>404</Typography>
                <Typography variant="h4">Sembra tu ti sia perso.</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', position: 'absolute', zIndex: '-1', bottom: '0' }}>
                <img src={cat} alt="gatto perso nello spazio" />
            </Box>
        </Box>
    )
}
