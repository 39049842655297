import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import logoBlu from '../edizioni-simone-logo-blu.png'; // with import

export default function CookiePolicy(props) {
    return (
        <Fragment>
            <Box px={4} py={2} mb={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', borderBottom:'1px solid #003370'}}>
            <img alt="logo" draggable="false" src={logoBlu} />
        </Box>
        <Container>
            <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vel pellentesque libero, eget volutpat erat. Quisque maximus lectus in leo efficitur fermentum. Quisque finibus massa urna. Quisque viverra sodales ipsum eget blandit. Cras mattis leo eget urna tempus imperdiet. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur eleifend tempor tellus. Ut vel lobortis felis, sit amet fermentum sapien. Morbi gravida, nisi vitae semper sodales, sapien ligula placerat ipsum, et auctor neque nulla non urna. Proin quis elit ante. Phasellus eu sagittis velit. In purus turpis, imperdiet a metus eu, lobortis aliquam leo. Nunc lobortis metus tortor, et scelerisque nibh ultrices sit amet. Nulla ultrices libero rutrum ex efficitur, eu varius justo accumsan. Ut est mi, eleifend venenatis ullamcorper eget, sollicitudin et justo. 
            </Typography>
            <Typography mt={1}>
                    Donec eu massa et urna vestibulum consectetur id sed dolor. Aliquam iaculis id nulla quis auctor. Fusce non consequat elit, in rhoncus risus. Vestibulum sem neque, pharetra eu tempor malesuada, hendrerit ac turpis. Aliquam rutrum, libero eget vehicula tincidunt, lectus lorem varius lacus, a molestie dolor sem eget turpis. Vestibulum interdum, enim et tristique porta, magna neque fringilla augue, id auctor leo risus mattis turpis. Phasellus scelerisque turpis vitae purus interdum euismod et id purus. Integer vitae rhoncus turpis, in consectetur sapien. Morbi facilisis, sem id laoreet tincidunt, eros sem volutpat mauris, vel cursus urna felis convallis erat. Donec consequat vehicula vestibulum. Etiam at lorem eget nunc eleifend commodo. 
            </Typography>
        </Container>
        {/* <Container >
            <Typography variant="h2">Cookie policy </Typography>
            <Typography>
                Questo sito web utilizza dei cookie per garantire il corretto funzionamento delle pagine web e migliorare l'esperienza di navigazione degli utenti. I cookie sono piccoli file di testo che vengono salvati sul dispositivo dell'utente quando visita un sito web. Alcuni cookie sono necessari per consentire la fruizione dei servizi offerti dal sito web, altri sono utilizzati per raccogliere informazioni statistiche in forma anonima sull'uso del sito.
            </Typography>
            <Typography variant="h4" mt={2}>Quali cookie utilizziamo </Typography>
            <Typography>
                I cookie che utilizziamo sono esclusivamente cookie tecnici, ovvero quelli indispensabili per il funzionamento del sito o per consentire all'utente di usufruire dei contenuti e dei servizi da lui richiesti. Secondo la direttiva dell'Unione Europea sui cookie tecnici, questi cookie possono essere installati senza chiedere il consenso dell'utente, in quanto non raccolgono dati personali né vengono usati per scopi di profilazione o di marketing.
                <br></br>I cookie tecnici che utilizziamo si dividono in:
                <ul style={{marginTop:'10px'}}>
                    <li>Cookie di sessione: sono temporanei e vengono eliminati alla chiusura del browser. Servono a gestire la sessione dell'utente e a memorizzare alcune preferenze, come la lingua o la dimensione dei caratteri.</li>
                    <li> Cookie persistenti: rimangono sul dispositivo dell'utente per un periodo di tempo determinato o fino a quando non vengono cancellati manualmente. Servono a ricordare le scelte dell'utente e a ottimizzare le funzionalità del sito.</li>
                </ul>
            </Typography>
            <Typography variant="h4" mt={2}>Come disabilitare i cookie</Typography>
            <Typography>Sebbene i cookie tecnici non richiedano il consenso dell'utente, quest'ultimo ha comunque la possibilità di disabilitarli o cancellarli in qualsiasi momento attraverso le impostazioni del proprio browser. Tuttavia, questo potrebbe compromettere il corretto funzionamento del sito o limitare l'accesso ad alcuni contenuti o servizi. </Typography>
            <Typography mt={1}>Per sapere come gestire i cookie nel tuo browser, puoi consultare le seguenti guide:</Typography>
            <Typography mt={1}>
                <ul>
                        <li>Chrome:<a href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a> </li>
                        <li>Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a></li>
                        <li>Safari: <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">https://support.apple.com/it-it/guide/safari/sfri11471/mac</a></li>
                        <li>Edge: <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a></li>
                </ul>
            </Typography>
            <Typography mt={1}>
                    Per maggiori informazioni sulla normativa sui cookie, puoi consultare il sito web dell'Autorità Garante per la protezione dei dati personali:<a href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2142939">https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/2142939</a>
            </Typography>
        </Container> */}
        </Fragment>
    )
}
