import { Typography, Grid, Skeleton, Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react"
import NewSezione from "./newSezione";

export default function ListSezioni(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [listSezioni, setListSezioni] = useState([]);
const [percentualiQuiz, setPercentualiQuiz] = useState([]);
    useEffect(()=>{
        setPercentualiQuiz([...props.percentualiQuiz]);
    },[props.percentualiQuiz])

    useEffect(() => {
        if (props.listSezioni !== undefined && props.listSezioni !== null) {
            setListSezioni(props.listSezioni);
            if (isLoading) {
                setIsLoading(false);
            };
        }
    }, [props.listSezioni])

    function changeNumeroQuiz(nuovoNumeroQuiz, index, quizDiPartenza) {
        if (props.changeNumeroQuizTot)
            props.changeNumeroQuizTot(nuovoNumeroQuiz, index, quizDiPartenza);
    }

    return (
        isLoading ? (
            <Grid container spacing={3} >
                {[0, 1, 2].map((id) =>
                    <Grid item xs={12} md={4} sx={{ width: '100%' }} key={id}>
                        <Skeleton variant="rounded" sx={{ width: '100%' }} height={60} />
                    </Grid>
                )}
            </Grid>
        ) : (
            <Fragment>
                {(props.numeroQuiz === 0) ? (
                    <Typography variant="h5" sx={{ textAlign: 'center', my: 5, color: '#003370' }}>Al momento non è possibile avviare il simulatore, si prega di riprovare tra qualche minuto.</Typography>
                ) : (
                    <Fragment>
                        {props.tipoTest === "Sim" && (
                            <Fragment>
                                <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                    Materie della prova di concorso
                                </Typography>
                                <Box sx={{ mt: 3 }}></Box>
                            </Fragment>
                        )}

                        {props.tipoTest === "Ese" && (
                            <Fragment>
                                <Fragment>
                                    <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                        Crea il tuo simulatore:
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 3, color: '#003370' }}>
                                        Seleziona il numero di quesiti per ogni materia. Se vuoi esercitarti su una sola materia puoi azzerare tutti i quiz delle altre materie. I quiz saranno somministrati in maniera casuale
                                    </Typography>
                                </Fragment>
                            </Fragment>
                        )}

                        {props.tipoTest === "Seq" && (
                            <Fragment>
                                <Typography variant="h5" sx={{ mt: 5, color: '#003370' }}>
                                    Quesiti in sequenza per materia:
                                </Typography>
                                <Typography variant="body1" sx={{ mb: 3, color: '#003370' }}>
                                    Tutti i quesiti sono in ordine di inserimento. Esercitati con tutti i quesiti in successione. Se interrompi la sessione del
                                    test vedrai la percentuale di completamento. Puoi interrompere e riavviare il test a
                                    distanza di giorni.
                                </Typography>
                            </Fragment>
                        )}


                        <Grid container spacing={3}>
                            {listSezioni.map((sezione, index) =>
                                sezione.quizDisponibili > 0 && (
                                    <Grid item xs={12} md={4} sx={{ width: '100%' }} key={index}>
                                        <NewSezione tipoTest={props.tipoTest} sezioneObj={sezione} indexSezione={index} percentualeQuiz={percentualiQuiz[index]}
                                            changeNumeroQuiz={changeNumeroQuiz}  />
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Fragment>
                )}
            </Fragment>
        )
    )
}