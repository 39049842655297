import { ReportProblem } from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogContent, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function Segnalazione(props) {

    const [testoSegnalazione, setTestoSegnalazione] = useState('');
    const [loadingInvioSegnalazione, setLoadingInviaSegnalazione] = useState(false);
    const [alertSegnalazioneInviataOpen, setAlertSegnalazioneInviataOpen] = useState(false);
    const [tipoAlertSegnalazione, setTipoAlertSegnalazione] = useState("success");
    const [dialogSegnalazioneOpen, setDialogSegnalazioneOpen] = useState(false)
    const abortController = new AbortController();
    const signal = abortController.signal;


    function changeTextSegnalazione(e) {
        setTestoSegnalazione(e.target.value);
    }

    function openDialogSegnalazione() {
        setDialogSegnalazioneOpen(prev => !prev);
    }

    function closeAlertSegnalazione() {
        setAlertSegnalazioneInviataOpen(false)
    }

    async function sendSegnalazione() {
        if (testoSegnalazione !== "") {
            let risoluzioneBrowser = window.innerWidth + 'x' + window.innerHeight;
            let quizHtml = "";
            quizHtml += "<p><b>Domanda: </b><br>" + props.domanda.title + "</p>";
            quizHtml += "<div>";
            for (let i = 0; i < props.domanda.choices.length; i++) {
                quizHtml += "<b>Risposta n." + (i + 1) + ": </b><br>"
                quizHtml += props.domanda.choices[i].text + "<br>"
            }
            quizHtml += "</div>";

            let emailBody = testoSegnalazione + "<br>" + quizHtml;

            let data = {
                emailBody: emailBody,
                risoluzione: risoluzioneBrowser,
                idQuestionario: props.questionarioId,
                idQuiz: props.domanda.quizId,
                userAgent: navigator.userAgent
            }

            setLoadingInviaSegnalazione(true);

            let url = process.env.REACT_APP_FETCH_URL + "questionario/userFeedback";
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                signal: signal
            };

            try {
                let res = await fetch(url, requestOptions);
                if (res.status === 200) {
                    res.json().then(
                        result => {
                            setLoadingInviaSegnalazione(false);

                            if (result.success) {
                                setDialogSegnalazioneOpen(false);
                                setTestoSegnalazione('');
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('success')
                            }
                            else {
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('error')
                            }
                        }
                    )
                } else {
                    setLoadingInviaSegnalazione(false);
                    setAlertSegnalazioneInviataOpen(true)
                    setTipoAlertSegnalazione('error')
                }
            } catch (error) {

                setLoadingInviaSegnalazione(false);
                setAlertSegnalazioneInviataOpen(true)
                setTipoAlertSegnalazione('error')
            }
        }
    }

    return (
        <>
            <Button disabled={loadingInvioSegnalazione} size="small" color="warning" variant="outlined" sx={{ ml: { xs: 0, md: 2 }, fontSize: '10px' }} onClick={openDialogSegnalazione}>
                <ReportProblem sx={{ mr: 1, fontSize: '15px' }} />
                Invia segnalazione
            </Button>
            <Dialog maxWidth="md" fullWidth open={dialogSegnalazioneOpen}> {/*Dialog Segnalazioni*/}
                <DialogContent>
                    <Typography variant='h3'>Segnala un problema</Typography>
                    <TextField
                        value={testoSegnalazione}
                        onChange={changeTextSegnalazione}
                        sx={{ mt: 1 }}
                        fullWidth
                        placeholder='Spiegaci il problema che hai riscontrato'
                        multiline
                        rows={4}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mt: 2 }}>
                        <Button variant="text" sx={{ mr: 1 }} onClick={openDialogSegnalazione}>Annulla</Button>
                        <Button disabled={loadingInvioSegnalazione || testoSegnalazione === ""} variant='contained' color="success" onClick={sendSegnalazione}>
                            {loadingInvioSegnalazione ? "Invio in corso..." : " Invia segnalazione"}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar onClose={closeAlertSegnalazione} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertSegnalazioneInviataOpen} autoHideDuration={3000}>
                <Alert onClose={closeAlertSegnalazione} severity={tipoAlertSegnalazione} sx={{ width: '100%' }}>
                    {tipoAlertSegnalazione == 'success' ? 'Segnalazione inviata' : 'Errore durante l\' invio della segnalazione'}
                </Alert>
            </Snackbar>
        </>
    )
}