import { Box, Typography, Divider, Link } from '@mui/material';
import Logo from '../../edizioni-simone-logo-blu.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function Footer(props) {
    return (
        <Box >
            <Divider sx={{ mt: 5, mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link target="_blank" alt="Edizioni Simone" href="https://edizioni.simone.it/" underline="none">
                    <img draggable="false" alt="Logo Simone Edizioni" width={100} src={Logo}></img>
                </Link>
                <Box sx={{ display: 'flex', alignItems: 'center', color: '#003370' }}>
                    <Typography variant='subtitle2' sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>Seguici sui nostri social:</Typography>
                    <Link target="_blank" alt="Pagina Facebook Edizioni Simone" href="https://it-it.facebook.com/EdizioniSimone/" underline="none">
                        <FacebookIcon sx={{ fontSize: '2rem' }} />
                    </Link>
                    <Link target="_blank" alt="Account Instagram Edizioni Simone" href="https://www.instagram.com/edizionisimone/?hl=it" underline="none">
                        <InstagramIcon sx={{ fontSize: '2rem', mx: 2 }} />
                    </Link>
                    <Link target="_blank" alt="Canale Youtube Edizioni Simone" href="https://www.youtube.com/channel/UC7Sl8bFGpJMiilhozwnMBnQ" underline="none">
                        <YouTubeIcon sx={{ fontSize: '2.5rem' }} />
                    </Link>

                </Box>
            </Box>
        </Box>
    )
}