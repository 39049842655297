import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { BaseOptionChart } from './charts';
import { CardHeader, Box } from '@mui/material';

BaseGraph.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function BaseGraph({ title, subheader, chartLabels, chartData, ...other }) {

    const chartOptions = merge(BaseOptionChart(), {
        plotOptions: { bar: { columnWidth: '16%' } },
        fill: { type: chartData.map((i) => i.fill) },
        labels: chartLabels,
        yaxis: {
            show: true
        },
        xaxis: {
            type: "string"
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    return y
                },
            },
        },
    });

    return (
        <Box {...other}>
            <CardHeader title={title} subheader={subheader} />
            <Box dir="ltr">
                <ReactApexChart type="line" series={chartData} options={chartOptions} height={150} />
            </Box>
        </Box>
    );

}
