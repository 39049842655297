import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function AlertCookies(props){

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    function acceptCookiesMethod(){
        if(props.acceptCookiesMethod) props.acceptCookiesMethod();
    }

    function goToInformativaCookie(){
        let url = "https://edizioni.simone.it/informativa-sulla-privacy-quiz"
        window.open(url, '_blank').focus();
    }

    return(
        <Box className="backdrop-cookies">
        <Box className="cookies-eu-banner">
          <Container sx={{ py: 2 }}>
            <Typography align="left" variant="h6">Informativa sui cookie</Typography>
            <Typography align='left' variant={isMobile ? "body2" : "body1"}>
              Per migliorare la tua esperienza di navigazione, questo sito utilizza cookie tecnici.<br></br>
              Puoi cliccare su “Scopri di più” per leggere l'informativa sulla privacy,
              oppure su "Accetta" per acconsentire all' uso dei cookie tecnici.
            </Typography>
            <Box sx={{ display: "flex", flexDirection:{xs:'column',md:'row'}, justifyContent: "space-between", alignItems: "center", mt: 2 }}>
              <Button fullWidth={isMobile} onClick={goToInformativaCookie} variant="outlined">Scopri di più</Button>
              <Button fullWidth={isMobile} variant="contained" onClick={acceptCookiesMethod} sx={{mt:{xs:1,md:0}}}>Accetta (cookie tecnici)</Button>
            </Box>
          </Container>
        </Box>
      </Box>
    )
}