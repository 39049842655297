import axios from "axios"
export const api = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_FETCH_URL,
})

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
    const statusCode = error.response?.status
    if (statusCode === 403) {
        window.location.href = process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_PREFIX + "/sessione-scaduta";
    } 
    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})
